.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


.rotateIcon {
    animation: spin infinite 1.5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.buttonDocument {
    width: 80%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: start !important;
    word-break: break-all;
}

.buttonDocumentDiv {
    height: 30px;
}

.buttonDocumentDiv:hover > span:nth-child(2) {
    display: flex;
    align-items: center;
}

.buttonDocumentDiv > span:nth-child(2) {
    display: none;
}

.buttonDocumentDivMobile > span:nth-child(2) {
    display: flex;
    align-items: center;
}

.paddingRightForDropdown > .css-1cjaqx8-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0 !important;
}

.disabledButton {
    cursor: not-allowed !important;
    color: #767676 !important;
    border: gray 1px solid !important;
    background: #eaeaea !important;
}

.changeColorAccountCircle {
    color: rgba(0, 0, 0, 0.54) !important;
}

.textWrap > span:nth-child(2) {
    text-align: justify;
}

.deleteViewButton {
    width: 45px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.viewPaper {
    min-width: 275px;
    margin-bottom: 25px;
    width: calc(100% - 45px);
}

@media (min-width: 500px) {
    .viewCardTypeOne {
        padding-right: 0 !important;
    }
}

@media (max-width: 500px) {
    .deleteViewButton {
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -12px;
        top: -20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #ffeeee;
    }

    .viewPaper {
        width: 100%;
    }
}

a:visited:not(.MuiButton-root) {
    color: blue;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #036aaa;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.listItemTextOneRow span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px white;
}